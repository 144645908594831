.welcome-msg-login {
  margin: 50px 0 50px 0;
}

.support-msg {
  margin-top: 50px;
  color: "#47525d";
  font-size: 14px;
}

.description-div {
  background: #000;
}

.form-label {
  color: #cbcbcb;
}

.logo {
  width: 130px;
  max-width: 100%;
  margin-left: -5px;
}

.div-form-container {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  padding-top: 10vh;
}

.div-login-left {
  padding-top: 20px;
  background: #fff;
  min-height: 100vh;
}

.inputNoFormat {
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #00aef3;
  border-radius: 0;
  width: 400px;
  max-width: 100%;
  color: #333;
  padding: 0;
}

.inputNoFormat:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

@media only screen and (max-width: 1024px) {
  .description-div {
    display: none;
  }

  .welcome-msg-login {
    margin: 30px 0 50px 0;
    font-size: 14px;
  }

  .support-msg {
    margin-top: 30px;
    color: "#47525d";
    font-size: 12px;
  }
  .div-login-left {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .div-form-container {
    width: 100%;
  }
}
